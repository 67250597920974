<div class="container">
  <app-page-title [text]="'articles.pageTitle' | fms" [description]="'articles.pageSubtitle' | fms"></app-page-title>
  <h2>{{ "articles.subHeader" | fms }}</h2>
  <section class="mt-16">
    <stb-chip
      [rebrand]="true"
      [iconName]="'check'"
      [chipType]="'choice'"
      [formControl]="articles"
      (chipClicked)="setFilter($event.chip)"
      chipType="choice"
    ></stb-chip>
  </section>
  @if (isEnglish) {
    <section class="mt-24">
      <stb-alert [surfaceVariant]="'surface-1'" type="info" width="fluid" [rebrand]="true">
        {{ "articles.articlesOverview.noTranslationsAlert" | fms }}
      </stb-alert>
    </section>
  }
  <section class="mt-32 articles">
    @for (article of articles$ | async; track article.key) {
      @if (article.openInNewTab) {
        <stb-card-navigation
          [colorVariant]="'onSurface1'"
          [fluid]="true"
          [size]="'compact'"
          [text]="article.title"
          [openInNewWindow]="article.openInNewTab"
          [trackId]="'article-card-' + article.key"
          [link]="article.url"
        >
        </stb-card-navigation>
      } @else {
        <stb-card-navigation
          [colorVariant]="'onSurface1'"
          [fluid]="true"
          [size]="'compact'"
          [text]="article.title"
          [trackId]="'article-card-' + article.key"
          [route]="article.route"
        >
        </stb-card-navigation>
      }
    }
  </section>
  <section class="my-48">
    <h2 class="mb-16">{{ "articles.faq.header" | fms }}</h2>
    <section class="faq-section">
      <app-card>
        <app-articles-faq></app-articles-faq>
      </app-card>
      <app-card>
        <img src="https://assets.storebrand.no/icons/rebrand/contact-advisor.svg" alt="Contact advisor" />
        <h3 class="mt-16">{{ "articles.contactCard.title" | fms }}</h3>
        <p class="font-body-demibold mt-24">{{ "articles.contactCard.question" | fms }}</p>
        <p class="mt-08">
          <a [href]="'articles.contactCard.sendEmail.link' | fms" target="_blank">{{
            "articles.contactCard.sendEmail" | fms
          }}</a>
        </p>
        <p>
          <span>{{ "articles.contactCard.call" | fms }}</span
          >&nbsp;
          <a href="tel:22312000">22 31 20 00</a>
        </p>
        <p class="font-body-demibold mt-24">{{ "articles.contactCard.payoutQuestion" | fms }}</p>
        <p class="mt-08">
          <a [href]="'articles.contactCard.sendEmailPayout.link' | fms" target="_blank">{{
            "articles.contactCard.sendEmail" | fms
          }}</a>
        </p>
        <p>
          <span>{{ "articles.contactCard.call" | fms }}</span
          >&nbsp;
          <a href="tel:22 31 10 52">22 31 10 52</a>
        </p>
      </app-card>
    </section>
  </section>
</div>
